import * as React from "react";
import { Container, Segment, Header, Item } from "semantic-ui-react";
import { withLayout } from "../components/Layout";
import Title from "../components/Title";
import OssPolicy from "../components/OssPolicy";
import GitHubOrgCards from "../components/GitHubOrgCards";
import OSSDonationList from "../components/OSSDonationList";
import OpenSSFNews from "../components/OpenSSF";

const pageTitle = "Open Source Software";

const OssPage: React.FC = () => {
  const cc0 = <a href="https://creativecommons.jp/sciencecommons/aboutcc0/" target="_blank" rel="noreferrer noopener">CC0</a>;
  const openSSF = <a href="https://openssf.org/" target="_blank" rel="noreferrer noopener">Open Source Security Foundation</a>
  
  return (
    <Container fluid className="bg-sabo">
      <Container>
        {/* Header */}
        <Title title={pageTitle} />

        <Segment vertical>
          <Header as="h2" id="policy">サイボウズのOSSポリシー</Header>
          <p>
            サイボウズ社員がオープンソースソフトウェアに関する活動を行いやすくすることを主な目的として、会社の基本方針を「OSSポリシー」という文書にまとめる作業を行いました。<br />
            完成したOSSポリシーは{cc0} (いかなる権利も保有しない、いわゆるパブリックドメイン)で広く他の企業の方々にも活用いただけるよう以下で公開しました。
          </p>
          <OssPolicy />
          <p>
            OSSポリシーについてブログ記事を公開しておりますので、こちらの記事もどうぞ。
          </p>
          <Segment>
          <Item.Group link divided>
            <Item href="https://blog.cybozu.io/entry/oss-policy" target="_blank" rel="noreferrer noopener">
              <Item.Content>
                <Item.Header>サイボウズのオープンソースソフトウェアポリシーを紹介します</Item.Header>
                <Item.Description>
                  OSS準備室長を務めていた ymmt (@ymmt2005) です。 過去形なのは、OSS準備室は 7 月末で解散したためです。
                  OSS準備室では、サイボウズ社員がオープンソースソフトウェアに関する活動を行いやすくすることを主な目的として、会社の基本方針を「OSSポリシー」…
                </Item.Description>
                <Item.Extra>8 Sep, 2018</Item.Extra>
              </Item.Content>
            </Item>
            <Item href="https://blog.cybozu.io/entry/2018/09/13/113000" target="_blank" rel="noreferrer noopener">
              <Item.Content>
                <Item.Header>業務利用しているOSSの休日個人開発は業務か？</Item.Header>
                <Item.Description>
                  こんにちは、OSS推進室長の岡田(@y_okady)です。先日公開したOSSポリシーについて、たくさんのご意見をいただきました。ありがとうございます！
                  その中の一つに、労務管理上の懸念についてのご指摘がありました。上長からの指示がなくても、業務利用してい…
                </Item.Description>
                <Item.Extra>13 Sep, 2018</Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
          </Segment>
        </Segment>
        <Segment vertical>
          <Header as="h2" id="github">GitHub</Header>
          <p>サイボウズのOSSは、主に以下のGithub organizationsで公開されています。</p>
          <GitHubOrgCards/>
        </Segment>
        <Segment vertical>
        <Header as="h2" id="openSSF">OpenSSF (Open Source Security Foundation)</Header>
          <p>サイボウズは {openSSF} に&nbsp;Generalメンバーとして加盟しています。詳しくは、ニュースリリースをご覧ください。</p>
          <OpenSSFNews/>
        </Segment>
        <Segment vertical>
          <Header as="h2" id="donation">寄付</Header>
          <p>
            これまでの寄付先は以下のとおりです。
          </p>
          <OSSDonationList/>
        </Segment>
      </Container>
    </Container>
  );
};

export default withLayout(OssPage, pageTitle);
